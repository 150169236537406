@import "../../Styles/variables.scss";

.button-exit-cross {
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
  background-color: white;
  > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  &:hover {
    background-color: $Grey10;
  }
}
