@import "../../Styles/variables.scss";

.button-connect {
  background-color: $Mint;
  color: white;
  border-radius: 50px;
  font-weight: 700;
  width: 140px;
  height: 36px;
  font-size: 1rem;
  border: 2px solid $Mint;
  cursor: pointer;

  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  font-family: "DM sans";
  &:hover {
    background-color: #ffffff;
    color: $Mint;
    border: 2px solid $Mint;
  }
}
