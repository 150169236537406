@import "../../../Styles/variables.scss";

.UserAccountContent {
  margin: 0 auto;
  flex: 1;
  max-width: 400px;

  @media (min-width: $mediumWindow) {
    margin: unset;
  }

  h2 {
    font-family: PulpDisplay;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: left;
    margin-bottom: 2rem;
    display: inline-block;
    @media (min-width: $mediumWindow) {
      font-size: 1.4rem;
    }
  }

  &__infos {
    border-top: 1px solid $StableGreyLight;
    margin-bottom: 20px;

    h5 {
      font-family: DM Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: $MainGrey;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    p {
      font-family: DM Sans;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: $MainBlack;
    }

    > div {
      display: flex;
    }

    img {
      cursor: pointer;
      display: block;
      margin-right: 0;
      margin-left: auto;
      margin-top: 10px;
    }

    @media (min-width: $mediumWindow) {
      margin-bottom: 40px;
      h5 {
        font-size: 14px;
        line-height: 25px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}
