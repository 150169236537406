@import "../../Styles/variables.scss";

.dropZone {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
  cursor: pointer;
  &__DragActive {
    background-color: $BackgroundColor;
  }

  &__Image {
    width: 40px;
    margin-bottom: 15px;
  }

  &__ModalUploadText {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    label {
      font-size: 0.85rem;
      color: $MainBlack;
    }
  }
  &__CustomFileUpload {
    font-weight: bold;
    text-decoration: underline;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 1.1em;
    &:hover {
      color: $MainYellow;
    }
  }
}
