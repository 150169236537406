@import "../../Styles/variables.scss";

.layout {
  max-width: $largeWindow;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex: 1;
  padding-left: 25px;
  padding-right: 25px;
}
