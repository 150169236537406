@import "../../../Styles/variables.scss";

.forgot-password {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 360px;
  margin-bottom: 120px;
  @media (max-width: $mediumWindow) {
    flex: none;
  }

  .forgot-password-inputs {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    margin-bottom: 10px;
    padding-bottom: 10px;
    > p {
      font-size: 12px;
      margin-bottom: 30px;
      color: $MainGrey;
      text-align: center;
    }
    .reset-password-success {
      color: $MainGreen;
    }
    .input-container {
      margin-bottom: 20px;
      height: 25px;
      width: 100%;
    }
  }
  .forget-password-message {
    height: 20px;
    .error-message {
      margin-bottom: 10px;
    }
  }

  .forgot-password-buttons {
    flex: 1;
    display: flex;
    justify-content: center;
    .go-to-login {
      font-size: 0.9rem;
      margin-right: 10px;
      font-weight: 700;
      color: $MainBlue;
      cursor: pointer;
      &:hover {
        color: $Mint;
      }
    }
  }
}
