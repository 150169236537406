@import "../../../Styles/variables.scss";

.RemboursementAidesTabContent {
  padding: 0rem 1rem;

  @media (min-width: $mediumWindow) {
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
  }

  &__CardContainer {
    @media (min-width: $mediumWindow) {
      width: 100%;
      row-gap: 2rem;
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
    }
  }

  &__AsterisqueOne {
    > p {
      font-size: 0.9rem;
      padding: 0 1.5rem;
    }
  }

  &__AsterisqueTwo {
    > p {
      font-size: 0.9rem;
      padding: 0 1.5rem;
      margin-bottom: 0.5rem;
    }
  }

  &__Image {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    @media (min-width: $mediumWindow) {
      margin-right: 3rem;
      order: 0;
    }
  }
}
