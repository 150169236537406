@import "../../../Styles/variables.scss";

.UserProjectsDoneContainerSingle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 30px;

  @media (min-width: $mediumWindow) {
    justify-content: start;
  }
}

.UserProjectsDoneContainerMultiple {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 20px;

  @media (min-width: $mediumWindow) {
    justify-content: space-between;
    column-gap: 0px;
  }
}
