@import "../../Styles/variables.scss";

.Status {
  font-size: 11px;
  font-style: italic;
  @media (min-width: $smallWindow) {
    font-size: 13px;
    font-weight: 300;
  }
}
