@import "../../Styles/variables.scss";

.header {
  height: $HeaderHeightResponsive;
  background-color: white;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 100;
  box-shadow: none;
  display: flex;
  box-shadow: 0px 4px 4px rgba(18, 40, 61, 0.04);
  @media (min-width: $smallWindow) {
    box-shadow: 0px 4px 4px rgba(18, 40, 61, 0.04);
    height: $HeaderHeight;
  }

  &__container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    @media (min-width: $normalWindow) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__logoContainer {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (min-width: $normalWindow) {
      margin: unset;
    }
  }

  &__logo {
    height: 25px;
    cursor: pointer;
    @media (min-width: $smallWindow) {
      height: 45px;
    }
  }

  &__iconBurger {
    @media (min-width: $normalWindow) {
      display: none;
    }
  }

  &__UserAccountContainer {
    display: none;

    @media (min-width: $normalWindow) {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding-top: 10px;
      margin-left: 10px;
      gap: 10px;
    }
  }

  &__goToEspacePro {
    height: 45px;
    display: flex;
    flex-direction: column;
    img {
      height: 100%;
    }

    p {
      font-family: "PulpDisplay";
    }
  }

  &__UserAccount {
    cursor: pointer;
    display: flex;
    flex-direction: row;

    padding: 10px 20px 10px 10px;
    border-radius: 30px;
    background-color: $Mint;
    width: 155px;

    > a {
      width: 100%;
      color: white;
      font-family: PulpDisplay;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      white-space: nowrap;
    }

    &:hover {
      .header__UserAccountLogoutModalActive {
        display: block;
      }
    }
  }

  &__UserAvatar {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  button {
    padding: unset;
    cursor: pointer;

    color: white;
    font-size: 12px;
    font-weight: 400;
  }

  &__UserAccountLogoutModalActive {
    display: none;
    position: absolute;
    width: 100%;
    top: 50px;
    right: 0px;
    background-color: $Mint;
    padding: 20px;
    padding-top: 30px;

    text-align: left;
    a > p {
      white-space: normal;
      width: 100%;
      color: white;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    p {
      font-size: 12px;
    }

    &::before {
      content: "";
      position: absolute;
      background-color: white;
      top: 0;
      left: 0;
      width: 100%;
      height: 10px;
    }

    &:hover {
      display: block;
    }
  }
}
