@import "../../../Styles/variables.scss";

.maCommandeHero {
  padding-bottom: 0.3rem;
  flex: 1;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 0 25px;
  z-index: 2;

  @media (min-width: $mediumWindow) {
    position: relative;
    padding: 0;
    box-shadow: unset;
    height: 100%;
  }
  &__DynamicMargin {
    height: 2rem;
    @media (min-width: $mediumWindow) {
      height: 6vh;
      max-height: 8rem;
    }
    width: 100%;
  }

  &__Content {
    text-align: left;

    @media (min-width: $mediumWindow) {
      display: flex;
      justify-content: flex-end;
      padding-right: 1.9rem;
      position: sticky;
      top: 8rem;
    }
  }

  &__GoBackButton {
    display: flex;
    width: 100%;
    margin-bottom: 1.5rem;
    @media (min-width: $mediumWindow) {
      margin-bottom: 2.5rem;
    }
  }

  &__Header {
    display: flex;
    padding-bottom: 1.3rem;
    flex-direction: column;
    align-items: center;
    @media (min-width: $mediumWindow) {
      flex-direction: column;
      max-width: 22rem;
    }
  }

  &__HeaderText {
    h1 {
      font-family: PulpDisplay;
      font-size: 1.2rem;
      font-weight: 600;
      @media (min-width: $mediumWindow) {
        font-size: 2.1rem;
        margin-bottom: 1.2rem;
      }
    }
  }

  &__HeaderButton {
    margin-top: 1.1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    @media (min-width: $mediumWindow) {
      justify-content: flex-start;
    }
  }

  &__MenuDesktop {
    margin-top: 2rem;
    width: 100%;
    li {
      margin-bottom: 1.5rem;
      color: $Mint;
      font-weight: 500;
      cursor: pointer;
    }
  }

  &__anchorActive {
    p {
      color: black;
      text-decoration: underline;
    }
  }
}
