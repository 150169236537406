@import "../../../Styles/variables.scss";

.reset-password {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  max-width: 320px;
  @media (min-width: $mediumWindow) {
    flex: none;
    padding: 0;
  }
  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    > div {
      margin-top: 40px;
    }
  }
  .reset-password-inputs {
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__InputContainer {
    margin-bottom: 2rem;
  }

  .reset-password-message {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    height: 10px;
  }
  .reset-password-buttons {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
