@import "../../Styles/variables.scss";

.buttonUpload {
  background-color: $Grey10;
  color: $MainGrey;
  font-family: "DM Sans";
  border-radius: 50px;
  font-weight: 500;
  line-height: 20px;
  height: 30px;
  font-size: 0.8rem;
  padding: 0 0.6rem;
  box-shadow: 0px 1px 3px 1px rgba(16, 66, 69, 0.22);
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  white-space: nowrap;
  @media (min-width: $mediumWindow) {
    font-size: 0.9rem;
  }
  &:hover {
    background-color: #ffffff;
    color: $Mint;
    border: 1px solid $Mint;
  }
}
