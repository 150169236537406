@import "../../../Styles/variables.scss";

.reset-password-window {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  padding: 0 1.5rem;
}
