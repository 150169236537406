@import "../../../Styles/variables.scss";

.register-form {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: $mediumWindow) {
    flex: none;
    width: auto;
    padding-top: 1.25rem;
  }
  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    > div {
      margin-top: 2.5rem;
    }
  }
  .register-inputs {
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
    max-width: 25rem;
    .register-user-email-input {
      p {
        text-align: center;
        color: $MainYellow;
        margin-bottom: 1rem;
      }
    }
  }

  .register-notice {
    margin-bottom: 1rem;
    .register-instruction {
      font-size: 0.75rem;
      color: $Grey60;
      font-style: italic;
      max-width: 30rem;
      text-align: center;
    }
  }

  .register-passwordInput {
    margin-bottom: 1rem;
  }

  .register-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0.625rem;
    padding: 1rem 0;
  }
  .register-buttons {
    flex: 1;
  }
}
