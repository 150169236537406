@import "../../Styles/variables.scss";

.ISO9001 {
  padding: 5px;
  margin-top: 5px;
  border-radius: 10px;
  > img {
    width: auto;
  }
}
