@import "../../Styles/variables.scss";

.reset-password-page {
  height: calc(100vh - $HeaderHeight);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: $mediumWindow) {
    padding-top: 2rem;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
  }
}
