@import "../../Styles/variables.scss";

.ButtonSelectTab {
  position: relative;
  &__Button {
    background-color: transparent;
    color: $Mint;
    border: 2px solid $Mint;

    border-radius: 50px;
    font-weight: 500;
    width: 9rem;
    height: 3rem;
    font-size: 0.95rem;
    cursor: pointer;

    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    font-family: "DM sans";
  }

  &__Notification {
    position: absolute;
    right: 0;
    top: -0.3rem;
  }

  &__Active {
    background-color: $Mint;
    color: white;
  }
}
