@import "../../Styles/variables.scss";

.contact-page {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  > :nth-child(n) {
    max-width: 350px;
    margin-right: 20px;
  }
  > :nth-child(3n) {
    margin-right: 0px;
  }
  @media (max-width: $mediumWindow) {
    flex-direction: column;
    align-items: center;
    > :nth-child(n) {
      margin-bottom: 40px;
      width: 100%;
      max-width: none;
      margin-right: 0px;
    }
  }
}
