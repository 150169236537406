@import "../../../Styles/variables.scss";

.uploadFileDisplay {
  flex: 1;
  padding: 1rem 0rem;
  margin-top: 1rem;
  max-width: 100%;
  &__FileInformation {
    flex: 1;
    font-size: 0.8em;
    font-weight: 400;
    padding: 0.4rem 0.5rem;
    border: 1px solid $BorderColor;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.2rem 0rem;

    > p {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 5px;
    }
  }

  &__DeleteButton {
    font-size: 14px;
    color: $MainGrey;
    @media (max-width: $smallWindow) {
      display: flex;
    }
  }
}
