// Colors
$MainBlue: #3b4863;
$MainYellow: #ffa929;
$MainOrange: #ed8c00;
$MainBlack: #191b1e;
$SecondBlack: #44474f;
$MainLightBlue: #0288d1;
$DarkGreen: #05a358;
$MainGreen: #71b051;
$OtherGreen: #44ba82;
$Turquesa: #46d1ca;
$MainRed: #d81159;
$MainPurple: #50249f;
$LightPuprle: #9860c3;
$MainGrey: #50545f;
$Grey80: #7e7e7e;
$Grey60: #9e9e9e;
$Grey35: #c7c7c7;
$Grey25: #d7d7d7;
$Grey10: #efefef;
$Grey05: #f7f7f7;
$Yellow80: #ffba54;
$Yellow60: #ffcb7f;
$Yellow35: #ffe1b4;
$Yellow25: #ffe9c9;
$Yellow10: #fff6ea;
$Yellow05: #fffbf4;
$ErrorMessage: #f0a14e;
$RedErrorMessage: rgb(248, 66, 66);
$BorderColor: #d3d4d7;
$BackgroundColor: #f8f8f8;
$BackgroundColorTwo: #f5f6f7;
$BackgroundColorThree: #f8f8f8;
$BackgroundColorBlue: #c3daf3;
$BackgroundColorBlueTwo: #80b9f0;
$UnderlineBlue: #b7d3f0;
$highlightBlue: #0073e0;
$AnchorLightBlue: #0069db;
$LightBlue: #c3daf3;
$LightGrey: #d3d4d7;
$AnchorButtonGrey: #ebecef;
$BackgroundDarkBlue: #085fb0;
$BackgroundBlue: #4096e8;
$BackgroundDarkBlue: #085fb0;
$BackgroundLightBlue: #dbe9f8;
$BackgroundBlueSky: #e5f1fc;
$BackgroundOrange: #f0a14e;
$BackgroundGrey: #e5e5e5;
$ButtonOrange: #ed8c00;
$StableGreyLight: #a8aaaf;
$StableGreyLight02: #7c7f87;
$OffBlack: #191b1e;
$AssuranceBlueLight: #f3f8fc;

// Rebranding Colors
$Mint: #08b77f;
$BrunswickGreen: #025e52;
$BrunswickGreen01: #025e52;
$BrunswickGreen02: #05f0d1;
$BrunswickGreen03: #73fcea;
$MintDark01: #057652;
$MintLight01: #0aeba4;
$MintLight02: #4ff8c2;
$MintLight03: #76f9d0;
$MintLight04: #9dfbdd;
$MintLight05: #c4fdeb;
$MintLight06: #ebfef8;
$White: #ffffff;
$Sunglow: #ffcc51;
$SunglowLight01: #ffedc2;
$SunglowLight02: #fff3d6;
$SunglowDark02: #ffb60a;
$Black: #012330;
$PlatiniumLight05: #f6f7fb;
$PlatiniumLight04: #e5e4e2;
$PlatiniumLight01: #d8d7d4;
$PlatiniummLight03: #f6f7fb;
$Platinium: #e5e5e5;
$PlatiniumDark01: #b1afa9;
$PlatiniumDark02: #9e9b94;
$PlatiniumDark03: #8b877e;
$PlatiniumDark04: #76726b;
$PlatiniumDark05: #605d57;
$BlackBlueLight04: #c3edfe;
$BlackBlueLight05: #ebf9ff;
$Vermilion: #ff3032;

// Responsive width
$mobile: 360px;
$smallWindow: 576px;
$mediumWindow: 868px;
$normalWindow: 1024px;
$largeWindow: 1180px;
$superLargeWindow: 1440px;

// Height
$HeaderHeight: 92px;
$HeaderHeightResponsive: 50px;
$FooterHeightDesktop: 335px;
$FooterHeightMobile: 812px;
$OverHeaderHeight: 0px;

//Font weight
$font-weights: (
  "regular": 400,
  "medium": 500,
  "bold": 600,
  "black": 700,
);

// Font sizes mobile
$font-sizes-mobile: (
  "description": 16px,
  "mainDescription": 21px,
  "title": 34px,
  "mainTitle": 40px,
);

// Font sizes Desktop
$font-sizes: (
  "description": 16px,
  "mainDescription": 30px,
  "title": 40px,
  "mainTitle": 55px,
  "dateTitle": 93px,
);

// Animations speed
$tabAnimation: 300ms;
