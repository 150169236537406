@import "../../../Styles/variables.scss";

.InstallationTabContent {
  padding: 0rem 1rem;
  @media (min-width: $mediumWindow) {
    display: flex;
    padding: 0rem;
  }
  &__Installation {
    margin-bottom: 2rem;
    display: flex;
    @media (min-width: $mediumWindow) {
      box-shadow: 0px 2px 13px -4px rgba(16, 66, 69, 0.22);
      border-radius: 4px;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 1rem;
      padding-top: 2rem;
      margin-right: 1rem;
      max-width: 25rem;
      flex: 1;
      min-height: 9rem;
      order: 1;
      margin-bottom: 1rem;
    }
  }

  &__Text {
    margin-left: 1rem;
  }

  &__Title {
    margin-bottom: 1rem;
    > p {
      font-weight: 500;
    }
  }

  &__Image {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    @media (min-width: $mediumWindow) {
      margin-right: 3rem;
      order: 0;
    }
  }

  &__OpenModal {
    margin-top: 1rem;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }
}
