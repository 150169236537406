@import "../../../Styles/variables.scss";

.maCommandeContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 3rem;
  &__Title {
    font-size: 1.1rem;
    font-weight: 700;
    @media (min-width: $mediumWindow) {
      scroll-margin-top: calc(#{$HeaderHeight} + 1.95rem);
    }
  }

  &__ProjectSummary {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid $LightGrey;
  }

  &__ProductsInfoBoxes {
    margin-top: 1.5rem;
  }

  &__Paying {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid $LightGrey;
  }

  &__ProductInfoBox {
    margin-bottom: 1rem;
  }

  &__MyDocumentsContent {
    width: 100%;
    padding-top: 1rem;
    @media (min-width: $mediumWindow) {
      padding-top: 2rem;
    }
  }

  &__LoadingComponent {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 2rem;
  }
}
