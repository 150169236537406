@import "../../../Styles/variables.scss";

.UserProjectsContentDetail {
  width: 100%;

  h2 {
    font-family: PulpDisplay;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: left;
    margin-bottom: 2rem;
    display: inline-block;
    @media (min-width: $mediumWindow) {
      font-size: 1.4rem;
    }
  }

  &__ProjectsDone {
    width: 100%;
  }

  &__ProjectsWIP {
    width: 100%;
    margin-bottom: 50px;
  }

  &__LoadingComponent {
    display: flex;
    justify-content: center;
  }
}
