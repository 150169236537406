@import "../../Styles/variables.scss";

.singleFileDisplay {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 0.6rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid $BorderColor;

  &__DocumentName {
    font-weight: 500;
    font-size: 0.9rem;
    display: flex;
    color: $MainBlack;
    line-height: 17px;
    margin-left: 1rem;
    @media (min-width: $mediumWindow) {
      font-size: 1rem;
    }
  }

  &__Picto {
    background-color: transparent;
  }
}
