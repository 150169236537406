@import "../../../Styles/variables.scss";

.UserAccountHero {
  padding-bottom: 0.3rem;
  flex: 1;

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 0 25px;
  z-index: 2;
  @media (min-width: $mediumWindow) {
    position: relative;
    padding: 0;
    box-shadow: unset;
    height: 100%;
  }
  &__DynamicMargin {
    height: 2rem;
    @media (min-width: $mediumWindow) {
      height: 12vh;
      max-height: 8rem;
    }
    width: 100%;
  }

  &__Content {
    text-align: left;
    @media (min-width: $mediumWindow) {
      display: flex;
      justify-content: flex-end;
      padding-right: 1.9rem;
    }
  }
  &__Header {
    display: flex;
    padding-bottom: 1.3rem;
    @media (min-width: $mediumWindow) {
      flex-direction: column;
      max-width: 22rem;
    }
  }
  &__HeaderImage {
    margin-right: 1rem;
    @media (min-width: $mediumWindow) {
      order: 3;
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      img {
        height: 133px;
      }
    }
  }
  &__HeaderText {
    h1 {
      font-family: PulpDisplay;
      font-size: 1.2rem;
      font-weight: 600;
      color: white;
      @media (min-width: $mediumWindow) {
        font-size: 2.1rem;
        margin-bottom: 1.2rem;
      }
    }
    h3 {
      font-family: PulpDisplay;
      font-size: 0.8125rem;
      margin-top: 0.45rem;
      line-height: 1rem;
      color: white;
      @media (min-width: $mediumWindow) {
        font-size: 1.2rem;
        line-height: 1.5rem;
      }
    }
  }
}
