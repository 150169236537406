@import "./variables.scss";

.tab-arrow-enter {
  transform: rotate(0deg);
}
.tab-arrow-enter-active {
  transform: rotate(180deg);
  transition: all 500ms ease;
}
.tab-arrow-enter-done {
  transform: rotate(180deg);
}
.tab-arrow-exit {
  transform: rotate(0deg);
  transition: all 500ms ease;
}
