@import "../../../Styles/variables.scss";

.register-window {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  @media (max-width: $mediumWindow) {
   gap: 2rem;
   width: 100%;
   padding: 4rem 2rem 0;
  }
}
