@import "../../Styles/variables.scss";

.ProjectFollowUp {
  background-color: white;

  @media (min-width: $mediumWindow) {
    min-height: calc(100vh - $HeaderHeight - $FooterHeightDesktop);
  }

  &__TabArea {
    background-color: $BackgroundColor;
    padding-bottom: 2rem;
    @media (min-width: $mediumWindow) {
      padding-bottom: 0rem;
    }
  }

  &__LoadingComponent {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  &__ErrorMessage {
    display: flex;
    flex: 1;
    justify-content: center;
    padding-bottom: 1rem;
  }

  &__SubHeader {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    @media (min-width: $mediumWindow) {
      margin-bottom: 1rem;
    }
  }

  &__SubHeaderMessage {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    @media (min-width: $mediumWindow) {
      margin-top: 0rem;
    }
    > p {
      font-weight: 500;
      text-align: center;
      font-size: 1.1rem;
    }
  }

  &__Tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
    @media (min-width: $mediumWindow) {
      justify-content: center;
    }
  }

  &__Tab {
    &:first-child {
      @media (min-width: $mediumWindow) {
        margin-right: 2rem;
      }
    }
  }

  &__Content {
    flex: 1;
  }

  &__Container {
    flex: 1;
  }

  &__TabContentDesktop {
    display: none;
    @media (min-width: $mediumWindow) {
      display: flex;
      padding-bottom: 5rem;
    }
  }

  &__TabsContainer {
    padding-top: 2.5rem;
    flex: 1;
    max-width: 100%;
  }

  &__SuiviChantierTabsContent {
  }

  &__Banner {
    background-color: $Mint;
    color: white;
    padding: 1rem;
    p {
      text-align: center;
      margin-bottom: 0.7rem;
    }
  }

  &__BannerMessage {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    img {
      margin-top: 0.3rem;
      margin-right: 0.5rem;
    }
  }

  &__BannerLink {
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
  }
}
