@import "../../../Styles/variables.scss";

.ChantierContent {
  &__Desktop {
    @media (min-width: $mediumWindow) {
      display: flex;
      flex: 1;
    }
  }

  &__OpencloseBlock {
    margin-bottom: 2rem;
    flex: 1;
    @media (min-width: $mediumWindow) {
      margin-right: 2rem;
      &:last-child {
        margin-right: 0rem;
      }
    }
  }
}
