@import "../../Styles/variables.scss";

.newsletterInput {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 20px;
  @media (max-width: $normalWindow) {
    justify-content: center;
  }
  > input {
    border-radius: 15px;
    border: none;
    width: 180px;
    margin-right: 5px;
    padding-left: 15px;
    font-size: 12px;
    color: $MainGrey;
    font-family: Roboto;
    &::placeholder {
      font-size: 12px;
      color: $MainGrey;
      font-family: Roboto;
    }
  }
  .buttonAction {
    height: 20px;
    width: 20px;
  }
}
