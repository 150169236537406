@import "../../Styles/variables.scss";

.modal {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  background-color: rgb(255, 255, 255);
  z-index: 999;
  padding: 15px;
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 3px 1px rgba(16, 66, 69, 0.22);
  > .button-exit-cross {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
