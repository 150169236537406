@import "./Styles/variables.scss";
@import "./Styles/PulpDisplayFont.scss";

/* Reset rules */
html,
body {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  > .swiper-button-prev {
    color: white !important;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

button {
  border: none;
  background-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

textarea:focus,
input:focus {
  outline: none;
}
textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

// Local font declaration

// TTF
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-ExtraLight.ttf");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-ExtraBold.ttf");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

//EOT
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-ExtraLight.eot");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Light.eot");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Regular.eot");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Medium.eot");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-SemiBold.eot");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Bold.eot");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-ExtraBold.eot");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

//woff
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-ExtraLight.woff");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Light.woff");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Regular.woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Medium.woff");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-SemiBold.woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Bold.woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-ExtraBold.woff");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

// WOFF2
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-ExtraLight.woff2");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Light.woff2");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Regular.woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Medium.woff2");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-SemiBold.woff2");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-Bold.woff2");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "PulpDisplay";
  src: url("./assets/fonts/PulpDisplay/PulpDisplay-ExtraBold.woff2");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
