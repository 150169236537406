@import "../../../Styles/variables.scss";

.authentication-credentials {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  width: 100%;

  @media (min-width: $mediumWindow) {
    flex: none;
    padding: 0;
    max-width: 320px;
  }

  &__loading-container {
    width: 100%;
    height: 100%;
    min-height: 16.8rem;
    display: flex;
    justify-content: center;
    > div {
      margin-top: 40px;
    }
  }
  &__login-notice {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 15px;
    .register-successfull {
      font-size: 14px;
      color: $MainGreen;
    }
  }

  &__inputs {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .input-container {
      margin-bottom: 20px;
      height: 25px;

      @media (min-width: $mediumWindow) {
        height: 35px;
      }
    }
  }

  &__TextInput {
    margin-bottom: 2rem;
  }

  &__PasswordInput {
    margin-bottom: 2rem;
  }

  .authentication-message {
    margin-bottom: 1.2rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
  }
  .authentication-buttons {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .go-to-forget-password {
      margin-top: 1rem;
      font-size: 1rem;
      width: 11rem;
      color: $MainBlue;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: $MainYellow;
      }
    }
  }
}
