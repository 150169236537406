@import "../../../Styles/variables.scss";

.ModalRescheduleRDV {
  width: 83vw;
  max-width: 600px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  overflow-y: hidden;
  text-align: center;

  &__ModaleTitle {
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    color: $MainBlack;
    font-weight: 700;
    font-family: "DM Sans";
    text-align: center;
  }

  &__CallUs {
    border: 2px solid $MainGrey;
    border-radius: 30px;
    margin-top: 1rem;
    margin-bottom: 2rem;

    p {
      color: #000000;
      font-family: DM Sans;
      font-weight: 700;
      font-size: 1rem;
      white-space: nowrap;
      padding: 10px 17px;
    }
  }
}
