@import "../../../Styles/variables.scss";

.paymentInfoBlock {
  margin-bottom: 1rem;
  @media (min-width: $mediumWindow) {
    padding-top: 1rem;
  }

  &__MainContainer {
    @media (min-width: $mediumWindow) {
      display: flex;
      flex-direction: row;
      gap: 3rem;
    }
  }

  &__PriceInfo {
    max-width: 500px;
    flex: 1;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__PriceInfoLine {
    display: flex;
    max-width: 500px;
    margin-bottom: 0.5rem;
    > p {
      flex: 1;
    }
    > p:last-child {
      text-align: end;
    }
    span {
      font-size: 0.8rem;
    }
  }

  &__PriceInfoLastLine {
    display: flex;

    p {
      flex: 1;
      font-weight: 700;
    }
    > p:last-child {
      text-align: end;
    }
  }

  &__LineSeparation {
    border-top: 2px solid black;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  &__PaymentInfo {
    flex: 1;
    margin-top: 2rem;
    p {
      margin-bottom: 0.3rem;
    }
    > p:first-child {
      margin-bottom: 1rem;
    }
    @media (min-width: $mediumWindow) {
      margin-top: unset;
    }
  }
  &__PaymentInfoModalité {
    display: flex;
    flex-direction: row;
  }
}

.ModalitéPopUP {
  width: 20px;
  height: 20px;
  background-color: $LightGrey;
  border-radius: 50%;
  color: white;
  text-align: center;
  margin-left: 10px;
  position: relative;
  display: inline-block;
}

.ModalitéPopUP:hover {
  .ModalitéPopUPText {
    visibility: visible;
  }
}
.ModalitéPopUPText {
  visibility: hidden;
  width: 300px;
  background-color: $LightGrey;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 2px;

  position: absolute;
  z-index: 1;
  bottom: 130%;
  left: 50%;
  margin-left: -150px;
}

.ModalitéPopUP .ModalitéPopUPText::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $LightGrey transparent transparent transparent;
}
