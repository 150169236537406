@import "../../../Styles/variables.scss";

.business-contact-view {
  border: 1px solid $MainYellow;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  flex: 1;
  position: relative;
  .picto {
    position: absolute;
    top: 0px;
    height: 50px;
    width: 50px;
    border: 1px solid $MainYellow;
    border-radius: 50%;
    background-color: white;
    margin-top: -25px;
    > img {
      height: 100%;
    }
  }
  > h3 {
    font-weight: 700;
    font-size: 22px;
    color: $MainBlue;
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: 0.07em;
    line-height: 25px;
  }
  > p {
    color: $MainBlack;
    letter-spacing: 0.02em;
    font-size: 15px;
    line-height: 25px;
  }
  .contact-error-message {
    color: $MainYellow;
    letter-spacing: 0.02em;
    line-height: 22px;
    font-weight: 700;
    margin-top: 10px;
  }
  .contact-information {
    margin-top: 10px;
    margin-bottom: 15px;
    > p {
      color: $MainYellow;
      letter-spacing: 0.02em;
      line-height: 22px;
      font-weight: 700;
    }
  }
}
