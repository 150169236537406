@import "../../Styles/variables.scss";

.buttonGoBack {
  &__Button {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  p {
    color: $StableGreyLight;
    font-family: "DM Sans";
    font-size: 1rem;
    font-weight: 500;
    margin-left: 0.5rem;
    @media (min-width: $mediumWindow) {
      font-size: 1rem;
      margin-left: 0.7rem;
    }
  }

  img {
    @media (min-width: $mediumWindow) {
      height: 1rem;
    }
  }
}
