@import "../../../Styles/variables.scss";

.ModalUpdatePassword {
  width: 83vw;
  max-width: 600px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  overflow-y: hidden;

  &__ModaleTitle {
    margin-top: 1rem;
    font-size: 1rem;
    color: $MainBlack;
    font-weight: 700;
    font-family: "DM Sans";
    text-align: center;
  }

  &__PasswordInput {
    margin-top: 2rem;
    width: 100%;
    max-width: 300px;
    > p {
      font-family: "DM Sans";
      font-size: 1rem;
      margin-bottom: 0rem;
    }
  }

  &__ButtonContainer {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    @media (min-width: $mediumWindow) {
      flex-direction: row;
      gap: 3rem;
    }
  }

  &__ErrorMessage {
    color: red;
    padding-top: 10px;
    font-size: 13px;
    margin: 15px;
  }
}
