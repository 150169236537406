@import "../../../Styles/variables.scss";

.codeParrainBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  &__TitleContainer {
    h4 {
      font-family: "PulpDisplay";
      font-weight: bold;
      font-size: 1.3125rem;
      color: $MainBlack;
    }
  }

  &__CodeContainer {
    align-self: center;
    text-align: center;
    padding: 13px 50px;
    border: 1px dashed $BorderColor;
    position: relative;

    .codeParrain {
      position: relative;
      color: $Mint;
      font-size: 1.125rem;
      font-family: "DM Sans";
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .lottieContainer {
      position: absolute;
      top: -5px;
      right: -25px;
    }
  }

  &__TextDescription {
    p:first-child {
      margin-bottom: 0.7rem;
    }
  }
}

.popUpCopied {
  font-size: 0.7rem;
  position: absolute;
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: -25px;
}
