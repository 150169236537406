@import "../../Styles/variables.scss";

.buttonAction {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  padding: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  cursor: pointer;
  > img {
    height: 100%;
    width: 100%;
  }
  > p {
    color: $Mint;
    font-size: 130%;
  }
}
.buttonAction.orange {
  background-color: $Mint;
  .arrowOrange {
    display: none;
  }
  &:hover {
    background-color: #ffffff;
    color: #Mint;
    border: 2px solid $Mint;
    .arrowWhite {
      display: none;
    }
    .arrowOrange {
      display: block;
    }
  }
}
.buttonAction.white {
  background-color: rgb(255, 255, 255);
  border: 1px solid $Mint;
  .arrowWhite {
    display: block;
  }
  .arrowOrange {
    display: none;
  }
  &:hover {
    background-color: $Mint;
    .arrowOrange {
      display: block;
    }
    .arrowWhite {
      display: none;
    }
  }
}
