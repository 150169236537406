@import "../../Styles/variables.scss";

.login {
  min-height: calc(100vh - $HeaderHeight);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  @media (max-width: $mediumWindow) {
    align-items: flex-start;
    background-color: white;
  }
}
