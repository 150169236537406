@import "../../../Styles/variables.scss";

.register-welcome {
  flex: 1;
  box-sizing: border-box;
  @media (max-width: $mediumWindow) {
    flex: none;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .register-welcome-message {
    width: 100%;
    > p {
      font-family: 'PulpDisplay';
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 8px;
    }
  }
}
