@import "../../Styles/variables.scss";

.productInfoBox {
  background-color: white;
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  @media (min-width: $smallWindow) {
    flex-direction: row;
  }

  &__Image {
    flex: 1;
    margin-right: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    img {
      width: 100%;
      max-width: 150px;
    }
  }

  &__Content {
    flex: 2;
  }
  &__Title {
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  &__Information {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  &__Price {
    font-weight: 500;
  }
}
