@import "../../Styles/variables.scss";

.OpenCloseBlock {
  box-shadow: 0px 2px 10px 1px rgba(16, 66, 69, 0.22);
  background-color: white;
  padding: 1rem 0rem;
  cursor: pointer;
  transition: all 300ms ease;
  @media (min-width: $mediumWindow) {
    padding: 1rem;
    min-height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__Header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__Title {
    margin-bottom: 1rem;
    @media (min-width: $mediumWindow) {
      margin-bottom: 0rem;
    }
    > h4 {
      font-weight: 600;
      font-size: 1.1rem;
      text-align: center;
    }
  }

  &__Status {
    position: relative;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    @media (min-width: $mediumWindow) {
      margin: 0rem;
    }
    > p {
      font-style: italic;
      font-weight: 500;
      text-align: center;
      max-width: 10rem;
    }
    > img {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__TabContent {
    overflow: hidden;
    transition: height $tabAnimation ease;
  }

  &__BottomArrow {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
  }
}

.tabIsOpen {
  @media (min-width: $mediumWindow) {
    transform: translateY(2.5rem);
    box-shadow: none;
    color: $Mint;
  }
}
