@import "../../Styles/variables.scss";

.footer {
  display: flex;
  position: relative;
  z-index: 10;
  flex: 1;
  background-color: $Black;
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  height: $FooterHeightDesktop;
  @media (max-width: $normalWindow) {
    height: auto;
  }
  .footerContainer {
    display: flex;
    justify-content: space-between;
    @media (max-width: $normalWindow) {
      flex-direction: column;
      align-items: center;
    }
    .footerCenter {
      display: flex;
      margin-left: 20px;

      @media (max-width: $normalWindow) {
        flex-direction: column;
        align-items: center;
        margin-left: unset;
      }
    }
    .footerLeft {
      display: flex;
      flex-direction: column;

      @media (max-width: $normalWindow) {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 20px;
        gap: 20px;
        width: 100%;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
        order: 2;
      }
    }
    .footerColumn {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      text-align: start;

      @media (max-width: $normalWindow) {
        align-items: center;
        margin-bottom: 15px;
        margin-right: unset;
      }
      .footerTitle {
        font-family: PulpDisplay, serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
      }
      .footerLinks {
        display: flex;
        flex-direction: column;
        @media (max-width: $normalWindow) {
          align-items: center;
        }
      }
      .footerText {
        font-family: PulpDisplay, serif;
        font-size: 14px;
        font-weight: 100;
        line-height: 22px;
      }
      a {
        &:hover {
          color: $Sunglow;
        }
      }
    }
  }
  .socialMedias {
    margin-left: -20px;
  }
}
