@import "../../Styles/variables.scss";

.register-page {
  height: calc(100vh - $HeaderHeight);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: $mediumWindow) {
    justify-content: flex-start;
    align-items: flex-start;
    background-color: White;
  }
}
