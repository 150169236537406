@import "../../../Styles/variables.scss";

.HeaderNavigation {
  display: none;
  position: absolute;
  top: $HeaderHeightResponsive;
  right: 0;
  height: calc(100vh - $HeaderHeightResponsive);
  width: 100vw;
  background-color: $Mint;
  overflow-y: scroll;
  overscroll-behavior: none; // Not supported by IE, EDGE and Safari
  padding: 0px 20px;

  @media (min-width: $smallWindow) {
    top: $HeaderHeight;
    height: calc(100vh - $HeaderHeight);
  }

  @media (min-width: $normalWindow) {
    overflow-y: visible;
    backdrop-filter: unset;
    background-color: unset;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: unset;
    right: unset;
    padding: unset;
  }

  &__navigationContent {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 10px;

    @media (min-width: $normalWindow) {
      flex: 1;
      width: 100%;
      height: 100%;
      padding: unset;
      padding-top: 10px;
      margin: unset;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__navigationList {
    position: relative;
    cursor: pointer;
    width: 100%;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: $normalWindow) {
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 30px;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: unset;
    }
  }

  &__navigationListItem {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: $normalWindow) {
      height: 100%;
    }

    > li {
      height: 100%;
      display: flex;
      flex-direction: column;
      width: auto;
      position: relative;
      @media (min-width: $normalWindow) {
        display: flex;
        flex-direction: column;
      }
    }

    p,
    a {
      font-family: PulpDisplay;
      font-size: 20px;
      line-height: 25px;
      color: White;
      @media (min-width: $normalWindow) {
        font-family: PulpDisplay;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: center;
        color: $MainGrey;
      }
    }

    @media (min-width: $normalWindow) {
      margin: unset;
      //padding-right: 30px;
      margin-top: 15px;

      justify-content: unset;

      nav {
        display: none;
      }
      &:hover {
        nav {
          display: flex;
        }
      }
    }
  }

  &__ContactUs {
    display: none;
    @media (min-width: $normalWindow) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      margin-bottom: 0px;
      &:hover {
        .ourHours {
          display: block;
        }
      }
    }
  }

  &__CallUs {
    border: 2px solid $MainYellow;
    border-radius: 30px;
    width: 155px;

    @media (min-width: $normalWindow) {
      border: 2px solid $MainGrey;
      margin-top: 30px;
    }

    p {
      color: white;
      font-family: DM Sans;
      font-weight: 700;
      font-size: 18px;
      line-height: 15px;
      white-space: nowrap;
      padding: 10px 17px;

      @media (min-width: $normalWindow) {
        color: #000000;
      }
    }
    @media (min-width: $normalWindow) {
      margin-top: unset;

      :hover {
        .ourHours {
          display: block;
        }
      }
    }
  }

  &__Logout {
    padding: 10px 20px;
    background-color: white;
    border-radius: 25px;
    cursor: pointer;
    p {
      font-family: DM Sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
    }
    @media (min-width: $normalWindow) {
      display: none;
    }
  }

  &__UserAccountContainer {
    width: 100%;
    margin-bottom: 30px;

    font-family: DM Sans;
    font-size: 15px;
    line-height: 25px;
    text-align: left;
    color: white;

    > p {
      font-weight: 400;
      color: white;
      margin-bottom: 10px;
    }

    a {
      font-weight: 700;
      text-decoration: underline 1px;
      text-underline-offset: 1px;
    }

    @media (min-width: $normalWindow) {
      display: none;
    }
  }
  &__UserAccount {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    p {
      font-family: PulpDisplay;
      font-size: 13px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: left;
      color: white;
    }
  }
  &__UserAvatar {
    margin-right: 10px;
  }
}

.ourHours {
  text-align: center;
  :first-child {
    color: white;
    font-family: PulpDisplay;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 10px;

    @media (min-width: $normalWindow) {
      color: $Mint;
    }
  }

  color: white;
  font-family: PulpDisplay;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;

  @media (min-width: $normalWindow) {
    color: $StableGreyLight;
  }

  @media (min-width: $normalWindow) {
    display: none;
    position: absolute;
    background-color: white;
    top: 100px;
    right: -80px;
    width: auto;
    white-space: nowrap;
    padding: 20px 30px;
  }
}

.underline {
  display: none;
  @media (min-width: $normalWindow) {
    display: block;
    border: 0.1em solid white;
    background-color: white;
    border-radius: 2px;
    margin: 5px 15px;
  }
}
.active {
  border: 0.1em solid $Mint;
  background-color: $Mint;
}

@media (max-width: $normalWindow) {
  .HeaderNavigation.responsiveMenuActive {
    display: flex;

    -webkit-animation: scale-in-tr 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: scale-in-tr 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}

@-webkit-keyframes scale-in-tr {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-tr {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
