@import "../../../../Styles/variables.scss";

.RemboursementAidesContentDetail {
  &__Aide {
    box-shadow: 0px 2px 10px 1px rgba(16, 66, 69, 0.22);
    border-radius: 4px;
    padding: 1rem 1rem;
    margin: 1rem 1rem;
    display: flex;
    @media (min-width: $mediumWindow) {
      margin: 0rem 1rem;
      margin-right: 1rem;
      width: 25rem;
      flex: 1;
      min-height: 6rem;
      order: 1;
      height: 100%;
    }
  }

  &__AideContainer {
    order: 1;
    flex: 1;
  }

  &__Text {
    margin-left: 1rem;
  }

  &__Status {
    > p {
      font-size: 1rem;
    }
  }

  &__Information {
    span {
      white-space: nowrap;
      font-weight: 700;
    }
  }

  &__Title {
    margin-bottom: 1rem;
    > p {
      font-weight: 500;
    }
  }

  &__message {
    margin-bottom: 1rem;
  }
}
