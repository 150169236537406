@import "../../../Styles/variables.scss";
.headerDropdown {
  display: none;
  position: relative;

  &__menuSection {
    margin-bottom: 15px;

    @media (min-width: $normalWindow) {
      margin-bottom: unset;
    }
  }

  &__sectionName {
    p {
      font-size: 1rem;
      font-family: PulpDisplay;
      margin-bottom: 8px;
      color: white;
      text-align: start;
    }
    @media (min-width: $normalWindow) {
      p {
        color: $MainYellow;
        font-size: 0.688rem;
        font-weight: 400;
        z-index: 2;
        margin: unset;
        padding: 0 15px;
      }
    }
  }

  &__linkSection {
    margin-top: 10px;
    margin-bottom: 20px;
    p {
      font-family: PulpDisplay;
      font-size: 0.875rem;
      text-decoration: underline;
      padding: 0 15px;
      text-align: start;
    }
    @media (min-width: $normalWindow) {
      margin-left: 0px;
    }
  }

  &__sectionListItem {
    margin-left: 17px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0px;
    }

    a {
      font-family: PulpDisplay;
      font-size: 15.008px;
      color: white;
      @media (min-width: $normalWindow) {
        color: $MainGrey;
      }
    }

    @media (min-width: $normalWindow) {
      margin-bottom: unset;
      margin-left: unset;
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
      position: relative;
      z-index: 0;

      padding: 5px 15px;

      &::before {
        transform: scaleX(0);
        transform-origin: bottom left;
        content: " ";

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        transition: all 0.5s ease-out;
      }

      &:hover::before {
        transform: scaleX(1);
        transform-origin: bottom left;
        background-color: $BackgroundColorTwo;
        transition: transform 0.3s ease-out;
        z-index: 1;
      }

      &:last-child {
        margin-bottom: 0px;

        &:hover::before {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
        &:last-child {
          padding-bottom: 10px;
        }
      }

      a {
        font-family: PulpDisplay;
        font-size: 0.875rem;
        line-height: 1.125rem;
        z-index: 2;
      }
    }
  }

  .icon {
    display: none;
  }

  .active {
    display: flex;

    a {
      font-weight: 700;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      z-index: 2;
    }
  }

  &__navigationList {
    text-align: left;
    padding-top: 20px;

    @media (min-width: $normalWindow) {
      display: flex;
      flex-direction: column;
      background-color: white;
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
      border-radius: 8px;

      max-width: 560px;
      flex-wrap: wrap;
      flex-shrink: 0;
      padding-top: 15px;
      overflow: hidden;
    }
  }

  @media (min-width: $normalWindow) {
    position: absolute;
    top: 0;
    padding-top: 20px;
    margin-top: 30px;
    display: flex;
  }

  &__VoirTousLesGuides {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

    > button > p {
      padding: 0 15px;
    }
  }
}

.visible {
  display: flex;
  -webkit-animation: scale-in-ver-top 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-ver-top 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
