@import "../../../Styles/variables.scss";

.authentication-welcome {
  padding-top: 50px;
  padding-bottom: 49px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (min-width: $mediumWindow) {
    padding-top: 0;
    padding-bottom: 60px;
  }

  &__message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $OffBlack;
    max-width: 300px;
    font-family: "PulpDisplay";

    @media (min-width: $mediumWindow) {
      max-width: none;
    }

    h1 {
      font-size: 34px;
      font-family: PulpDisplay;
      font-weight: 700;
      margin-bottom: 2rem;
      @media (min-width: $mediumWindow) {
        font-size: 34px;
      }
    }
    p {
      text-align: center;
      @media (min-width: $mediumWindow) {
        font-size: 16px;
      }
    }
  }
}
