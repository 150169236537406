@import "../../Styles/variables.scss";

.sub-header-container {
  width: 100%;
  height: 120px;
  box-shadow: 0px 3px 3px 0px $Grey60;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  @media (max-width: $smallWindow) {
    margin-bottom: 25px;
  }
}

.sub-header-title {
  color: $MainYellow;
  font-size: 28px;
  letter-spacing: 0.7px;
  font-weight: bold;
}
