@import "./Styles/variables.scss";
@import "./Styles/animations.scss";

.App {
  font-family: "DM Sans", PulpDisplay, "Roboto", sans-serif;
}

.mobileOnly {
  @media (min-width: $mediumWindow) {
    display: none;
  }
}

.desktopOnly {
  display: none;
  @media (min-width: $mediumWindow) {
    display: block;
  }
}
