@import "../../Styles/variables.scss";

.UserProjects {
  width: 100%;
  background-color: $BackgroundColor;
  @media (min-width: $mediumWindow) {
    background: linear-gradient(90deg, white 50%, $BackgroundColor 50%);
  }

  &__Content {
    flex: 1;
    min-height: calc(
      100vh - #{$HeaderHeightResponsive} - #{$FooterHeightMobile}
    );
    @media (min-width: $mediumWindow) {
      display: flex;
      background-color: $BackgroundColor;
      min-height: calc(100vh - #{$HeaderHeight} - #{$FooterHeightDesktop});
    }
  }

  &__DynamicMargin {
    height: 2.5rem;
    z-index: -1;
    @media (min-width: $mediumWindow) {
      height: 15vh;
      max-height: 10rem;
    }
    width: 100%;
  }

  &__UserProjectsContent {
    flex: 1;

    min-height: calc(100vh - $HeaderHeightResponsive);
    @media (min-width: $mediumWindow) {
      padding-left: 4rem;
      padding-bottom: 3rem;
      min-height: calc(100vh - $FooterHeightDesktop - $HeaderHeight);
    }
  }
}
