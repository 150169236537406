@import "../../Styles/variables.scss";

.BannerContainer {
  @media (min-width: $mediumWindow) {
    width: 320px;
    height: 100%;
  }
}

.Blue {
  background-color: $BackgroundBlue;
}

.White {
  background-color: white;
}
