@import "../../../Styles/variables.scss";

.reset-password-welcome {
  display: flex;
  justify-content: center;
  &__message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $OffBlack;
    font-family: "PulpDisplay";

    p {
      font-size: 1.5rem;
      font-family: PulpDisplay;
      font-weight: 700;
      margin-bottom: 2rem;
      text-align: center;
      @media (min-width: $mediumWindow) {
        font-size: 2rem;
      }
    }
  }
}
