@import "../../../Styles/variables.scss";

.UserProjectCard {
  background-color: white;
  width: 100%;
  height: auto;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 2px 13px -4px rgba(16, 66, 69, 0.22);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $smallWindow) {
    max-width: 300px;
  }

  @media (min-width: $mediumWindow) {
    max-width: 340px;
  }

  &__Title {
    h3 {
      font-family: DM Sans;
      font-size: 20px;
      font-weight: 700;
      line-height: 18px;
      text-align: left;

      @media (min-width: $mediumWindow) {
        font-size: 20px;
        line-height: 26px;
      }
    }

    h4 {
      font-family: DM Sans;
      font-size: 18px;
      font-weight: 400;
      font-style: italic;
      line-height: 18px;

      text-align: left;
      @media (min-width: $mediumWindow) {
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        font-style: normal;
      }
    }
  }

  &__Type {
    margin-top: 1em;
    margin-bottom: 1em;
    height: 100%;

    p {
      text-align: left;
      font-family: DM Sans;
      font-size: 1rem;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: $MainGrey;
      margin-bottom: 0.2rem;

      @media (min-width: $mediumWindow) {
        font-size: 1rem;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  &__ButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 10px;
    padding: 0px 30px;

    @media (min-width: $mediumWindow) {
      padding: unset;
      //gap: 20px;
      flex-direction: row;
      justify-content: space-around;
    }
  }
}
