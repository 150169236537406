@import "../../../Styles/variables.scss";

.DocumentToSend {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 0.6rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid $BorderColor;

  &__DocumentName {
    font-weight: 500;
    font-size: 0.9rem;
    display: flex;
    color: $Grey80;
    line-height: 17px;
    @media (min-width: $mediumWindow) {
      font-size: 1rem;
    }
  }

  &__Mobile {
    @media (min-width: 1048px) {
      display: none;
    }
  }

  &__Desktop {
    @media (max-width: 1048px) {
      display: none;
    }
  }
}
