@import "../../../Styles/variables.scss";

.DemarchesAdministrativesContent {
  &__Desktop {
    @media (min-width: $mediumWindow) {
      display: flex;
      justify-content: center;
      flex: 1;
    }
  }
  &__OpencloseBlock {
    margin-bottom: 2rem;
    flex: 1;

    @media (min-width: $mediumWindow) {
      max-width: 30rem;
      margin-right: 2rem;
      &:last-child {
        margin-right: 0rem;
      }
    }
  }
}
