@import "../../../Styles/variables.scss";

.modalUploadDocumentContent {
  width: 83vw;
  max-width: 600px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  overflow-y: hidden;
  &__DocumentName {
    font-size: 16px;
    color: $MainBlack;
    font-weight: 700;
    font-family: "DM Sans";
  }

  &__AddDocumentInput {
    margin-top: 3rem;
    width: 100%;
    max-width: 300px;
    > p {
      font-family: "DM Sans";
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }

  &__UploadedFile {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 450px;
  }

  &__EmptySpace {
    height: 3rem;
    width: 100%;
  }

  &__Bottom {
    height: 50px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 2rem;
  }

  &__ErrorMessage {
    padding-top: 10px;
    font-size: 13px;
    margin: 15px;
  }
}
