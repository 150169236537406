@import "../../../Styles/variables.scss";

.tucoenergie-adress-view {
  border: 1px solid $MainYellow;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  flex: 1;
  position: relative;
  .picto {
    position: absolute;
    top: 0px;
    height: 50px;
    width: 50px;
    border: 1px solid $MainYellow;
    border-radius: 50%;
    background-color: white;
    margin-top: -25px;
    > img {
      height: calc(100% + 10px);
      margin-left: -5px;
      margin-top: -5px;
    }
  }
  > h3 {
    font-weight: 700;
    font-size: 22px;
    color: $MainBlue;
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: 0.07em;
    line-height: 25px;
  }
  .tucoenergie-adress {
    > p {
      color: $MainBlack;
      letter-spacing: 0.02em;
      font-size: 15px;
      line-height: 25px;
    }
    > p:first-child {
      font-weight: 700;
      color: $MainBlue;
    }
  }
}
