@import "../../Styles/variables.scss";

.MyAccount {
  width: 100%;
  background-color: $BackgroundColor;
  @media (min-width: $mediumWindow) {
    background: linear-gradient(
      90deg,
      $BackgroundBlue 50%,
      $BackgroundColor 50%
    );
  }

  &__Container {
    flex: 1;
    min-height: calc(
      100vh - #{$HeaderHeightResponsive} - #{$FooterHeightMobile}
    );
    @media (min-width: $mediumWindow) {
      display: flex;
      gap: 2rem;
      background-color: $BackgroundColor;
      min-height: calc(100vh - #{$HeaderHeight} - #{$FooterHeightDesktop});
    }
  }

  &__DynamicMargin {
    height: 2.5rem;
    z-index: -1;
    @media (min-width: $mediumWindow) {
      height: 15vh;
      max-height: 10rem;
    }
    width: 100%;
  }

  &__Content {
    flex: 1;

    @media (min-width: $mediumWindow) {
      // padding-left: 4rem;
      padding-bottom: 3rem;
      min-height: calc(100vh - $FooterHeightDesktop - $HeaderHeight);
    }
  }

  &__ParrainageContainer {
    flex: 1;
    margin-bottom: 3rem;
    @media (min-width: $mediumWindow) {
      margin-bottom: 0;
      // padding-left: 4rem;
      padding-bottom: 3rem;
    }
  }
}
