@import "../../Styles/variables.scss";

.progressBar {
  width: 100%;

  &__Content {
    background-color: $LightGrey;
    width: 100%;
    border-radius: 27px;
  }

  &__Progression {
    background-color: $Sunglow;
    border-radius: 27px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    white-space: nowrap;
    font-size: 0.9rem;
  }
  &__ProgressionPercentage {
    color: white;
    margin-right: 0.5rem;
    font-weight: 500;
  }

  &__Information {
    display: flex;
    justify-content: space-between;
    margin-top: 0.3rem;
  }

  &__Payed {
    font-weight: 700;
    color: $Sunglow;
  }
}
