@import "../../Styles/variables.scss";

.logo {
  display: flex;
  justify-content: center;
  height: 100%;
  width: auto;
  > img {
    height: 100%;
    width: auto;
  }
}
