@import "../../Styles/variables.scss";

.socialMedias {
  display: flex;
  width: 220px;
  justify-content: space-between;
  height: 50px;
  > div {
    height: 100%;
    img {
      height: 100%;
      width: auto;
      cursor: pointer;
    }
  }
}
