@import "../../../Styles/variables.scss";

.DocumentNotCompliant {
  padding-bottom: 0.8rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid $BorderColor;
  @media (min-width: $mediumWindow) {
    margin-top: 1.5rem;
  }
  &__Header {
    display: flex;
    justify-content: flex-start;
  }
  &__DocumentName {
    font-family: PulpDisplay;
    font-weight: 500;
    display: flex;
    align-items: center;
    img {
      margin-right: 0.6rem;
      height: 1.1rem;
      @media (min-width: $mediumWindow) {
        height: 1.3rem;
      }
    }
  }
  &__DocumentNameText {
    font-size: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > p {
      margin-right: 0.3rem;
    }
  }
  &__UploadButton {
    margin-top: 1rem;
    display: flex;
    justify-content: right;
  }
  &__Commentaire {
    font-size: 0.8125rem;
    margin-top: 1rem;
    @media (min-width: $mediumWindow) {
      font-size: 0.9rem;
    }
  }
}
