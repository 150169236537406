@import "../../../../Styles/variables.scss";

.PayementInfoDetailsBlock {
  margin-top: 2rem;

  &__CardContainer {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;

    @media (min-width: $mediumWindow) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
    }
  }

  &__ButtonContainer {
    margin: 2rem 0 1rem;
    > div {
      background-color: $LightGrey;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;

      padding: 0.313rem 1.125rem;
      border-radius: 1.75rem;

      font-weight: 500;
    }
  }
}
