@import "../../../Styles/variables.scss";
.uploadDocumentContent {
  flex: 1;
  padding-bottom: 4rem;
  &__ListContainer {
    flex: 1;
    > h2 {
      font-family: PulpDisplay;
      font-weight: 700;
      font-size: 1.1rem;
      text-align: left;
      margin-bottom: 2rem;
      display: inline-block;
      @media (min-width: $mediumWindow) {
        font-size: 1.4rem;
      }
    }
  }
  &__ToSendTitle {
    position: relative;
    &::before {
      position: absolute;
      content: attr(data-count);
      top: -0.75rem;
      right: -1.8rem;
      width: 1.3rem;
      height: 1.3rem;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $BorderColor;
      color: $MainGrey;
    }
  }
  &__SentTitle {
    position: relative;
    &::before {
      position: absolute;
      content: attr(data-count);
      top: -0.75rem;
      right: -1.8rem;
      width: 1.3rem;
      height: 1.3rem;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $BorderColor;
      color: $MainGrey;
    }
  }
  &__AddDocumentButton {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
    margin-top: 4rem;
    @media (min-width: $mediumWindow) {
      justify-content: flex-start;
    }
  }
  &__NotUploadedDocuments {
    margin-bottom: 3rem;
  }

  &__LoadingComponent {
    display: flex;
    justify-content: center;
  }
}
